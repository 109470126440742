import React, { FC } from 'react';
import styled from 'styled-components';
import { LayoutBase } from './LayoutBase';
import {
    Header,
    LayoutType,
    SEO } from '../components';

// const MainHome = styled.div`
//     width: 100vw;
//     height: 100vh;
//     padding: var(--space-l);
//     overflow: hidden;

//     @media ${mobileBreakPoint} {
//         padding: var(--space-s);
//     }
// `;

export const LayoutHome: FC<any> = ({ children }) => (
    <LayoutBase>
        <SEO/>
        <Header layoutType={LayoutType.HOME} />
        {/*<MainHome >{children}</MainHome>*/}
    </LayoutBase>
);
